import './App.css';
import * as React from 'react';
import TTIlogoLight from './images/tti_light.png'; 
import TTIlogoDark from './images/tti_dark.png'; 
import TXDOTlogoLight from './images/txdot_light.png';
import TXDOTlogoDark from './images/txdot_dark.png';
import EmissionsLogo from './images/truck_emissions.png';
import { CssBaseline, Box, Paper, Typography, Grid, Stack, styled, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwitchContent from './SwitchContent';
import { Brightness4, ContactMail } from '@mui/icons-material';
import { useEffect, useState, useCallback, useMemo } from 'react';

const DARK_MODE = 'dark';
const LIGHT_MODE = 'light';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5),
    '& > :first-child': {
      fontSize: '1.8rem',
    },
    '& > :nth-child(2)': {
      fontSize: '1rem',
    },
  },
}));

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  minHeight: '100vh',
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(3),
}));

export const handleEmissionsClick = () => {
  const emissionsUrl = 'https://app.powerbi.com/view?r=eyJrIjoiNjI0ZjQyOGMtM2IzYy00OTBjLTkyYzctOGVhMjkzMjZlYWMyIiwidCI6IjVmMThkYjczLTIwZTYtNGEwOC05ZWVjLWUzNjY5MTZiZTg3MSIsImMiOjN9';
  window.open(emissionsUrl, '_blank');
};

export const emptyCache = () => {
        if('caches' in window){
        caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
};

function App() {
  const navigate = useNavigate();
  const handleMainClick = () => navigate('/');
  const handleContactUsClick = () => navigate('/contact-us')
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? DARK_MODE : LIGHT_MODE);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => {
      setMode(event.matches ? DARK_MODE : LIGHT_MODE);
    };
    emptyCache();

    mediaQuery.addEventListener('change', handleChange);
    return () => {
    mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const TTIlogo = mode === 'dark' ? TTIlogoDark : TTIlogoLight;
  const TXDOTlogo = mode === 'dark' ? TXDOTlogoDark : TXDOTlogoLight;
  const toggleTheme = useCallback(() => {
    setMode((prevMode) => (prevMode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer>
        <Stack backgroundColor={theme.palette.mode === 'dark' ? '#1A2027' : "#ECEDED"} sx={{ flex: 1 }}>
          <Item>
            <Stack backgroundColor={theme.palette.mode === 'dark' ? '#1A2027' : "#14375a"} display="flex" justifyContent="space-between" direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
            <div sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Box sx={{ p: 0.5, m: 0.5,  display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "nowrap" , background: theme.palette.mode === 'dark' ? '#1A2027' : "#14375a"}}>
                  <img src={TXDOTlogo} alt="TxDOT" style={{ width: !isSmallScreen ? "170px" : "90px", height: !isSmallScreen ? "80px" : "50px", objectFit: "contain" }} />
                  <img src={TTIlogo} alt="TTI" style={{  width: !isSmallScreen ? "150px" : "90px", height:  !isSmallScreen ? "68px" : "60px", objectFit: "contain" }} />
                </Box>
            </div>
              <div sx={{display: "flex", alignItems: "center" , justifyContent: "space-between"}}>
                {!isSmallScreen ?
                  <Button onClick={handleMainClick} variant="h4" size="large" sx={{ m: 2, color:'white', fontWeight: 'bold', fontSize: 25 }}>
                    Border Wait Times
                  </Button> : (
                  <Button onClick={handleMainClick} size="small" sx={{ mt: 2, color:'white', fontWeight: 'bold', fontSize: 20 }}>
                    BWT
                  </Button> )}
              </div>

              <div sx={{display: "flex", alignItems: "center" , justifyContent: "center"}}>
                <Box sx={{ pr: 1.5, mt: 1.8}}>
                  {!isSmallScreen && 
                    <Tooltip title="Contact Us">
                      <IconButton onClick={handleContactUsClick}>
                        <ContactMail fontSize="large" sx={{color: "white"}}/>
                      </IconButton>
                    </Tooltip>
                  }
                  <Tooltip title= {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}>
                  {!isSmallScreen ? 
                    <IconButton onClick={toggleTheme} >
                      <Brightness4 fontSize="large" sx={{color: "white"}}/>
                    </IconButton> :
                    <IconButton onClick={toggleTheme} >
                      <Brightness4 sx={{color: "white", fontSize: "1.8rem"}}/>
                    </IconButton>  }
                  </Tooltip>
                </Box>
              </div>
            </Stack>
          </Item>
          <Box sx={{ p: isSmallScreen ? 0.5 : 2, pl: isSmallScreen ? 0.1 : 2.9, pr: isSmallScreen ? 0.1 : 2.9, flexGrow: 1 }}>
            <Item sx={{ borderRadius: '10px' }}>
              <SwitchContent />
            </Item>
          </Box>
          <FooterContainer>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
                  Information provided on this site is captured real time by sensors deployed at the crossings.
                </Typography>
              </Grid>
            </Grid>
          </FooterContainer>    
        </Stack>
      </AppContainer>
    </ThemeProvider>
  );
}
export default App;
